import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, FormControl, Select, MenuItem, InputLabel, IconButton, Typography, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout } from '../../../store/slices/authSlice';
import imageCompression from 'browser-image-compression';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		md: '600px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

export default function ChangeCharacter({
	open,
	setOpen,
	character,
	characterId,
	episodeIndex,
	setCardsToRenderFunc,
	
}) {
	const handleClose = () => setOpen(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const alert = useAlert();
	const [characterName, setCharacterName] = useState('');
	const token = useSelector((state) => state.auth.token);
	const navigate = useNavigate();

	const [imageFile, setImageFile] = useState('');
	const [imageInput, setImageInput] = useState('');
	const [imageName, setImageName] = useState('');
	const [wasImageChanged, setWasImageChanged] = useState(false);
	const [images, setImages] = useState([]);
	const location = useLocation();
	const projectId = location.pathname.slice(9, location.pathname.length);
	const [imagePreview, setImagePreview] = useState(null); 
	
	async function getImages() {
		try {
			const response = await axios.get(`${constants.BASE_URL}api/projects/get-images/${projectId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const filteredData = response.data?.images.filter(item => item !== null);
			setImages(
				filteredData.map((image, index) => ({
					image: image,
					index: index,
				}))
			); 
		} catch (error) {
			console.error('Failed to fetch images:', error);
			// Assuming there's an alert context or similar error handling
		}
	}

	useEffect(() => {
		if (open) {
		  getImages();
		  setImageFile('');
		  setImageName('');
		  setImageInput('');
		  setImagePreview(null);
		}
	  }, [open]);


	  const handleSubmit = async (e) => {
		if (stateLoading) {
			return;
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
	
		// Delete 'image' entry if no file was uploaded
		if (data.get('image')?.size === 0) {
			data.delete('image');
		}
	
		// Directly add imageInput as a string if present and no new file is uploaded
		if (imageInput && imageFile === '') {
			await request({ image: imageInput }); // Sending imageInput as part of a simple object
			return; // Exit after handling this case
		}
	
		// Process new file upload scenario
		if (data.get('image') && !imageInput) {
			if (await checkImageSize(imageInput)) {
				setCharacterName(data.get('name'));
				dispatch(addLoadingFlag('update-character'));
				if (data.get('image')) {
					const file = data.get('image')
					data.delete('image');
					try {
						const options = {
							maxSizeMB: 0.90,
						}
						const compressedFile = await imageCompression(file, options);
						data.append('image', compressedFile)
						await request(data)

					} catch (error) {
						alert.show(
							`Something went wrong with image compression.`,
							{ type: 'error' }
						);
					}
				} else {
					await request(data)
				}
			}
		} /* else {
			if (data.get('image') || data.get('imageSelect')) {
				await defaultImage(data)
			} else {
				const characterWithoutImage = {...character}
				delete characterWithoutImage.image
				const defaultData = {...characterWithoutImage}
				await defaultImage(defaultData)
			}
		} */

		async function request(updateData) {
			await axios
				.post(
					constants.BASE_URL +
					'api/projects/update-character-by-id/' +
					character.id,
					updateData,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose();
					alert.show(
						`'${
							characterName ? characterName : character?.name
						}' was successfully changed!`,
						{ type: 'success' }
					);
					setImageFile('');
					setImageName('');
					setImageInput('');
					setImagePreview(null);
					setCardsToRenderFunc();
					dispatch(removeLoadingFlag('update-character'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-character'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
					
					
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
					
				});
		}
		/* async function defaultImage (data) {
			dispatch(addLoadingFlag('update-character'));
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/update-character-by-id/' +
						character.id,
						data
					,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose()
					alert.show(
						`'${
							character?.name
						}' was successfully changed!`,
						{ type: 'success' }
					);
					dispatch(removeLoadingFlag('update-character'));
					setCardsToRenderFunc();
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-character'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
					
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
					
				});
		
			}; */
	};

	const handleFileChange = (event) => {
		// console.log(event);
		const file = event.target.files[0];
		// console.log(file);
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				// console.log('error');
				setImageFile('');
				setImageInput('');
				setImageName('');
				setImagePreview(null);
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageFile(event.target.value);
				setImageInput('');
				setImageName(file.name);
				const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result);
                };
                reader.readAsDataURL(file);
			}
		}
	};

	const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					setImageName('');
					setImagePreview(null);
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);
					setImageName('');

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};
	
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography
						sx={{
							mb: 2,
							p: 0,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
						textAlign={'center'}
					>
						CHANGE `{character?.name}`
					</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '70vh',
							overflow: 'scroll',
							paddingRight: '8px',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.PRIMARY,
							},
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row', // Explicitly set the flex-direction
								justifyContent: 'space-between', // This will space out the items from one end to the other
								alignItems: 'center', // This will vertically center align items in the container
								flexWrap: 'nowrap', // Prevents wrapping of items
							}}
						>
							<FormControl fullWidth margin="normal">
								<InputLabel id="image-select-label">Select from Image Gen</InputLabel>
								<Select
									labelId="image-select-label"
									name='imageSelect'
									id="imageSelect"
									value={imageInput}
									label="Select from Image Gen"
									onChange={(e) => {
										setImageFile('');
										setImageName('');
										setImagePreview(constants.IMAGE_URL + e.target.value);
										setImageInput(e.target.value);
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												'&::-webkit-scrollbar': {
													width: '2px',
													height: '0px',
												},
												'&::-webkit-scrollbar-thumb': {
													borderRadius: '6px',
													backgroundColor: constants.PRIMARY, // Use your theme's primary color or a custom color
												},
											}
										}
									}}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{images.map((item, index) => (
										<MenuItem key={index} value={item.image.image}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Use flexbox here */}
												<ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}> {/* Reduce default padding */}
													<img src={constants.IMAGE_URL + item.image.image} alt="" style={{ width: 50, height: 50 }} />
												</ListItemIcon>
												<ListItemText primary={item.image.title} />
											</Box>
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Typography sx={{ mx: 2, whiteSpace: 'nowrap' }}>OR</Typography>
							<Button
								variant='contained'
								component='label'
								sx={{
									height: '60px',
									marginTop: '16px',
									marginBottom: '8px',
								}}
							>
								Upload
								<input
									name='image'
									//value={imageFile}
									hidden
									accept='image/*'
									onChange={(e) => {
										setImageInput('');
										handleFileChange(e);
									}}
									type='file'
								/>
							</Button>
						</div>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center', // Centers horizontally
								alignItems: 'center', // Centers vertically
							}}
								>
						{imagePreview && (
                            <img
                                src={imagePreview}
                                alt="Upload Preview"
                                style={{width: '100%', maxWidth: '300px', marginTop: '10px' }}
                            />
                        )}
						</Box>
					</Box>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Change
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
